.home{
    width: 100%;
    height: 40rem;
    background-color: transparent;
}
.home-main{
    align-items: center;
    justify-content: center;
    min-height: 5rem;
    width: 100%;
    height: 35rem;
}
.wrapper{
    padding-top: 15rem;
    color: var(--white-color);
    text-align: center;
}
.wrapper .static-txt{
    font-size: 40px;
    font-weight: 400;
}
.wrapper .dynamic-txt{
  height: 90px;
  line-height: 90px;
  overflow: hidden;

}
 .dynamic-txt li{
    list-style: none;
    font-size: 40px;
    font-weight: 800;
    color: rgb(241, 238, 21);
    position: relative;
    top: 0;
    animation:  slide 6s steps(4) infinite;
 }

 @keyframes slide {
    100%{
        top: -360px;
    }
 }
 .dynamic-txt li span{
    position: relative;
 }
 .dynamic-txt li span::after{
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    top: 0;
    background: #0F2C4C;
    border-left: 2px solid white;
    animation: typing 1.5s steps(18) infinite;
 }


@keyframes typing{
    100%{
        left: 100%;
        margin: 0 -35px 0 35px;
    }
}

#particles {
    position: absolute;
    left: 0;
    width: 100%;
    height: 38rem;
    z-index: -1;

}
@media screen and (max-width: 460px) {
    .dynamic-txt li{
        font-size: 30px;
    }
    }
@media screen and (max-width: 364px) {
    .dynamic-txt li{
        font-size: 25px;
    }
    }
@media screen and (max-width: 364px) {
    .dynamic-txt li{
        font-size: 20px;
    }
    .home{
        height: 32rem;
    }
    #particles{
        height: 32rem;
    }
    }
@media screen and (max-width: 320px) {
   .wrapper{
    padding-top: 10rem;
   }
    }