.container{
    margin-top: 2rem;
    background: #fff;
    width: 100%;
    padding: 25px 40px 10px 40px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  }
                 
  .container form{
    padding: 30px 0 0 0;
    height: 30rem;
  }
  .container form .form-row{
    display: flex;
    margin: 32px 0;
  }
  form .form-row .input-data{
    width: 100%;
    height: 40px;
    margin: 0 20px;
    position: relative;
  }
  form .form-row .textarea{
    height: 70px;
  }
  .input-data input,
  .textarea textarea{
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    font-size: 17px;
    border-bottom: 2px solid rgba(0,0,0, 0.12);
    outline: none;
  }
  .input-data input:focus ~ label, .textarea textarea:focus ~ label,
  .input-data input:valid ~ label, .textarea textarea:valid ~ label{
    transform: translateY(-30px);
    font-size: 14px;
    color: #3498db;
    border: none;
  }
  .textarea textarea{
    resize: none;
    padding-top: 10px;
  }
  .input-data label{
    position: absolute;
    pointer-events: none;
    bottom: 10px;
    font-size: 16px;
    transition: all 0.3s ease;
  }
  .textarea label{
    width: 100%;
    bottom: 40px;
    background: #fff;
  }
  .input-data .underline{
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 100%;
  }
  .input-data .underline:before{
    position: absolute;
    content: "";
    height: 2px;
    width: 100%;
    background: #3498db;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.3s ease;
  }
  .input-data input:focus ~ .underline:before,
  .input-data input:valid ~ .underline:before,
  .textarea textarea:focus ~ .underline:before,
  .textarea textarea:valid ~ .underline:before{
    transform: scale(1);
  }
  .submit-btn .input-data{
    overflow: hidden;
    height: 45px!important;
    width: 25%!important;
  }
  .submit-btn .input-data .inner{
    height: 100%;
    width: 300%;
    position: absolute;
    left: -100%;
    background: -webkit-linear-gradient(right, #56d8e4, #095b81, #56d8e4, #095b81);
    transition: all 0.4s;
  }
  .submit-btn .input-data:hover .inner{
    left: 0;
  }
  .submit-btn .input-data input{
    background: none;
    border: none;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    position: relative;
    z-index: 2;
  }
  @media (max-width: 700px) {
    .container .text{
      font-size: 30px;
    }
    .container form {
      height: 37rem;
  }
    .container form .form-row{
      display: block;
    }
    form .form-row .input-data{
      margin: 35px 0!important;
    }
    .submit-btn .input-data{
      width: 40%!important;
    }
  }