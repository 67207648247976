.footer-link{
    display: flex;
    gap: 2rem;
    padding-top: 0.5rem;
}
.footer-link-icon{
  font-size: 1.5rem;
  transition: .2s ease-in-out;
  color: var(--primary-color);
}
.footer-2{
    background: var(--navyblue-color);
    color: var(--primary-color);
}
.footer-upper{
    justify-content: space-between;
    padding: 2rem 0;
    display: flex;
    flex-wrap: wrap;
}

.footer-icon{
   padding: 1.5rem 0;
   align-items: center;
   justify-content: center;
   display: flex;
   gap: 2rem;
}
.foot-logo{
    width: 14rem;
    margin-bottom: 2rem;
}
.upper-first h6{
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 500;
}
.upper-first p{
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 500;
}

.footer-mid, .footer-mid-2, .upper-2nd, .upper-first{
    width: 25%;
    padding: 0 1rem;
}
.footer-mid-2, .footer-mid h4{
    color: var(--primary-color);
}
.news-item{
    color: #999;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px dashed var(--primary-color);
}
.news-title{
    font-size: 13px;
    line-height: 22px;
}
.news-title a{
    color: var(--primary-color);
}
.news-title a:hover{
    color: var(--primary-color);
}

.upper-info{
    padding: 0;
    padding-top: 2rem;

}
.upper-info1 a{
    font-weight: 16px;
    font-weight: 500;
    padding-bottom: 0.5rem;
    color: var(--primary-color);
}
.footer-link-icon1{
    padding-right: 0.5rem;
}

.footer-about-link{
    display: flex;
    font-weight: 600;
    padding: 0;
    padding-top: 6rem !important;
   gap: 1rem;
}
.footer-bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--royalblue-color);
    padding: 1rem 0;
    color: var(--white-color);
}

@media (max-width: 1000px) {
    .footer-mid, .footer-mid-2, .upper-2nd, .upper-first{
        width: 50%;
    }
}
@media (max-width: 769px) {
    .footer-mid, .footer-mid-2, .upper-2nd, .upper-first{
        width: 100%;
    }
}
   

