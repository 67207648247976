.error{
    width: 100%;
    background: rgb(220, 244, 252);
    text-align: center;
}


.mainbox {
    margin: auto;
    height: 70vh;
    position: relative;
    padding-top: 4rem;
  }
  
  .not-found {
    width: 560px;
    height: 225px;
    margin-right: -10px;
  }
  .starry-sky {
    display: block;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
  .search-icon {
    display: inline-block;
  }
  .notfound-copy {
    color: #fff;
    position: fixed;
    right: 10%;
    text-align: right;
  }
 .error-title{
     font-weight: 700;
    font-size: 40px;
  }
 .error-a{
    font-weight: 400;
    color: var(--royalblue-color);
    border-bottom: none;
    text-decoration: none;
  }
  .error-a:hover {
    border-bottom: 2px solid var(--royalblue-color);
  }
  /* change to alternating star opacities */
  .all-stars {
    animation: blinkblink 7s linear infinite;
    color: #0a1821;
  }
  
  @keyframes blinkblink {  
    50% { opacity: 0; }
  }
  /* .moon {
  } */
  #input1[type=text] {
    color: #fff;
    background-color: #0a1821;
    padding:5px; 
    border: none; 
    border-bottom:2px solid #ccc; 
    font-size: 18px;
  }
  #input1[type=text]:focus {
    border-color:none; 
    border-bottom:2px solid #ccc;
    }
  /* /* mobile test * / */
  @media (max-width: 647px) { 
    .moon {
      padding-top: 400px;
    }
  }