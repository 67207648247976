.about-story-main {
  width: 100%;
  background-color: var(--whiteColor);
  margin-bottom: 0 !important;
  box-shadow: inset 7px -21px 38px -14px rgba(237, 237, 237, 1);
}
.about-story-box {
  padding: 6rem 0;
  border: none;
  flex-wrap: wrap;
  display: flex;
}
.about-story-card {
  width: 50%;
  padding-left: 2rem;
}
.about-story-card1 {
  width: 50%;
  height: 20rem;
}
.history-text {
  font-size: 28px;
  font-weight: 600;
  color: var(--primary-color);
}
@media screen and (max-width: 991px) {
  .card-box {
    margin: 0 2rem;
  }
  .blog-card {
    width: 100% !important;
  }
  .blog-card1 {
    width: 100%;
  }
  .blog-text-bottom {
    gap: 10rem;
  }
}
@media screen and (max-width: 767px) {
    .about-story-box {
        padding: 3rem 0;
      }
 .about-story-card{
    width: 100%;
 }
 .about-story-img{
    width: 100%;
    margin: 0 1rem;
 }
}
