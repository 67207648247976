.cards-wrapper {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 4rem;
    padding: 4rem;
    margin: 0 auto;
    width: max-content;
  }
  
  .card {
    height: 20em;
    width: 15em;
    font-size: 1.5em;
    color: var(--royalblue-color);
    border-radius: 1em;
    display: flex;
    align-items: flex-end;
    background-size: cover;
    background-position: center;
    box-shadow: 0 0 5em -1em rgb(210, 231, 250);
    position: relative;
    overflow: hidden;
    border: none;
    text-decoration: none;
  }
  .card1{
    background: url("https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/5.webp?updatedAt=1716448729354");
  }
  .card2{
    background: url("https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/1%20(2).webp?updatedAt=1716448729312");
  }
  .card3{
    background: url("https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/2%20(1).webp?updatedAt=1716448729289");
  }
  .card4{
    background: url("https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/6.webp?updatedAt=1716448729368");
  }
  .card5{
    background: url("https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/3%20(1).webp?updatedAt=1716448729314");
  }
  .card6{
    background: url("https://ik.imagekit.io/xjjzkwxra/RT-INFOTECH/4.webp?updatedAt=1716448729328");
  }
  
  .card:hover {
    transform: rotate(0);
  }
  
  .service-section-text-card{
    background: var(--white-color);
    width: 100%;
    margin-top: 80%;
    padding: 20px;
    height: 16rem;
  }

  .card h1 {
    font-size: 20px;
    font-weight: 500;
  }
  
  .card p {
    font-size: 16px;
  }
  
  .card .tags {
    display: flex;
  }
  
  .card .tags .tag {
    font-size: 18px;
    font-weight: 400;
    border-radius: 0.3rem;
    padding: 0 0.5em;
    margin-right: 0.5em;
    line-height: 1.5em;
    transition: all, var(--transition-time);
    background: var(--royalblue-color);
    color: var(--white-color);
    border: 2px solid var(--royalblue-color);
  }
  
  
  
 
  
  .card-grid-space .num {
    font-size: 3em;
    margin-bottom: 1.2rem;
    margin-left: 1rem;
  }
  
  .info {
    font-size: 1.2em;
    display: flex;
    padding: 1em 3em;
    height: 3em;
  }
  
  .info img {
    height: 3em;
    margin-right: 0.5em;
  }
  
  .info h1 {
    font-size: 1em;
    font-weight: normal;
  }
  
  /* MEDIA QUERIES */
  @media screen and (max-width: 1285px) {
    .cards-wrapper {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media screen and (max-width: 900px) {
    .cards-wrapper {
      grid-template-columns: 1fr;
    }
    .info {
      justify-content: center;
    }
  }
  
  @media screen and (max-width: 500px) {
    .cards-wrapper {
      padding: 4rem 2rem;
    }
    .card {
      max-width: calc(100vw - 4rem);
    }
  }
  
  @media screen and (max-width: 450px) {
    .info {
      display: block;
      text-align: center;
    }
    .info h1 {
      margin: 0;
    }
  }