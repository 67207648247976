.bulksms-card{
    width: 100%;
}
.bulksms-card-main{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.bulksms-card-box{
    text-align: center;
    width: 15rem;
    height: 14rem;
    background: var(--navyblue-color);
    margin: 2rem 0;
    padding: 20px;
}
.bulksms-card-img{
    color: var(--royalblue-color);
    font-size: 80px;
}
.bulksms-card-box:hover{
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
color: var(--primary-color);
}

@media screen and (max-width: 991px) {
    .bulksms-card-box{
        width: 20rem;
        height: 14rem;
    }
    .bulksms-card-main{
        justify-content: space-evenly;
    }
}