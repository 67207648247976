.servicetest {
  width: 100%;;
  padding: 4rem 0;
}
.servicetest-main {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}
.servicetest-main1 {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.servicetest-card {
  width: 22rem;
  height: 30rem;
  background: var(--royalblue-color);
  padding: 20px;
  border-radius: 2rem;
  color: var(--white-color);
}
.servicetest-card p {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.servicetest-card h4 {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  margin-top: 2rem;
}

.profile {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
}
.profile img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media screen and (max-width: 1140px) {
  .servicetest-card {
    width: 32%;
    height: 30rem;
  }
}

@media screen and (max-width: 810px) {
  .servicetest-main {
    align-items: center;
    justify-content: center;
  }
  .servicetest-main1 {
    align-items: center;
    justify-content: center;
  }
  .servicetest-card {
    width: 90%;
    height: 20rem;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 630px) {
  .servicetest-card {
    height: 26rem;
  }
}
@media screen and (max-width: 410px) {
  .servicetest-card {
    height: 28rem;
  }
}
