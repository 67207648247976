.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
:root {
  --primary-color:    #0B74B7;
  --royalblue-color: #086680;
  --navyblue-color: #c3f2ff;
  --black-color: #191919;
  --blue-color: #87e5ff;
  --white-color: #fff;
  --graytext: #6d6d6d;
  --deamblue-color: #EBF1F4;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.Container {
  max-width: 1280px;
  margin-inline: auto;
  padding-inline: 15px;
  overflow: hidden;
}
.flex {
  display: flex;
  align-items: center;
}
a {
  text-decoration: none;
}
li {
  list-style: none;
}
ul {
  margin-bottom: 0;
}
.flex {
  display: flex;
}
h1{
  font-size: 36px;
  font-weight: 600;
  color: var(--royalblue-color);
}
h5{
  font-size: 18px;
  font-weight: 500;
}
p {
  color: var(--deamblue-text);
}
h3 {
  color: var(--royalblue-color);
}