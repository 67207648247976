.contact-map {
  overflow: hidden;
  height: 0;
  padding-bottom: 30%;
  position: relative;
  -webkit-box-shadow: -1px 3px 20px 0 rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 3px 20px 0 rgba(0, 0, 0, 0.75);
  box-shadow: -1px 3px 20px 0 rgba(0, 0, 0, 0.75);
  margin-top: 6rem;
  margin-bottom: 2rem;
}
.contact-map iframe {
  border: 0;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
}
@media screen and (max-width: 767px) {
    .contact-map{
        margin-top: 0;
    }
}
