.monial-box {
  text-align: center;
  width: 100%;
  background: var(--royalblue-color);
  padding-top: 2rem;
  flex-wrap: wrap;
}
.monial-card {
  width: 60%;
  text-align: center;
  position: relative;
  left: 20%;
  top: 50%;
  margin: 4rem 0 4rem;
}
.monial-card p {
  color: var(--white-color);
}
.monial-card-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: auto;
  overflow: hidden;
}
.monial-title,
.monial-subtitle {
  color: var(--white-color);
}
