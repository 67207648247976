.wrapper{
    background-color: var(--deamblue-color);
    width: 100%;
    padding-bottom: 2rem;
}
.wrapper-box{
    display: flex;
    width: 100%;
}
.wrapper-text{
    width: 50%;
    text-align: left;
}
 .wrapper-img{
    width: 50%;
}
mark {
    background-color: var(--royalblue-color);
    color: var(--white-color);
}
.wrapper-text p{
   color: var(--black-color);
}

@media screen and (max-width: 769px){
    .wrapper-box{
        display: block;
    }
    .wrapper-text, .wrapper-img{
        width: 100%;
    }
    .wrapper{
        padding-top: 10rem;
    }
}