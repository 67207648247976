.cardscroll-box{width:100%;margin: 3rem 0;}
.cardscroll-box2{width:95%;background:var(--white-color);display:flex;align-items:center;justify-content:space-evenly;gap:2rem;flex-wrap:wrap}
.cardscroll-card{margin-top:1rem;margin-bottom:2rem;width:30rem;height:16rem;overflow:hidden;position:relative;border-radius:.5rem}
.card-img{width:100%;height:100%;object-fit:cover}
#service-main{
    position: relative;
    margin-top: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.service-text {
  margin-top: 2rem;
  text-align: center;
  color: var(--royalblue-color);
  padding-bottom: 2rem;
}
.service-header h1{
  color: var(--primary-color);
}
.service-header h1:hover{
  color: var(--primary-color);
  text-decoration: underline;
}
article {
  --img-scale: 1.001;
  --title-color: black;
  --link-icon-translate: -20px;
  --link-icon-opacity: 0;
  position: relative;
  border-radius: 16px;
  box-shadow: none;
  background: #fff;
  transform-origin: center;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  border: 2px solid rgb(238, 238, 238);
  height: 30rem;
    width: 20rem;
}

article a::after {
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
  cursor: pointer;
  content: "";
}
#in::after{
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    height: 600px;
    width: 200px;
    background-color: var(--royalblue-color);
    animation: move 1.5s infinite;
}

@keyframes move {
    100%{
        transform: translate(-50%, -50%) rotate(225deg);

    }
}
/* basic article elements styling */
article h2 {
    margin: 0 0 18px 0;
    font-size: 20px;
    letter-spacing: 0.06em;
    color: var(--primary-color);
    transition: color 0.3s ease-out;
}
.article-wrapper{
    z-index: 1;
    position: relative;
    background: var(--white-color);
    height: 29.5rem;
    width: 19.5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -0%);
    margin-top: 0.2rem;
}
figure {
  margin: 0;
  padding: 0;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

article img {
  max-width: 100%;
  transform-origin: center;
  transform: scale(var(--img-scale));
  transition: transform 0.4s ease-in-out;
}

.article-body {
  padding: 24px;
}

article a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #28666e;
}

article a:focus {
  outline: 1px dotted #28666e;
}

article a .icon {
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-left: 5px;
  transform: translateX(var(--link-icon-translate));
  opacity: var(--link-icon-opacity);
  transition: all 0.3s;
}

/* using the has() relational pseudo selector to update our custom properties */
article:has(:hover, :focus) {
  --img-scale: 1.1;
  --title-color: #28666e;
  --link-icon-translate: 0;
  --link-icon-opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}




.articles {
  display: grid;
  max-width: 100%;
  margin-inline: auto;
  /* padding-inline: 24px; */
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
}

/* @media screen and (max-width: 960px) {
  article {
    container: card/inline-size;
  }
  .article-body p {
    
  }
} */

@container card (min-width: 380px) {
  .article-wrapper {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 16px;
  }
  .article-body {
    padding-left: 0;
  }
  figure {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  figure img {
    height: 100%;
    aspect-ratio: 1;
    object-fit: cover;
  }
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0); 
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; 
  width: 1px;
}
