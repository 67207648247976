* {
  scroll-behavior: smooth;
}
.top-btn {
  width: 80px;
  height: 80px;
  position: fixed;
  right: 0;
  margin-top: -5rem;
  padding: 0.5rem;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  z-index: 3;
  bottom: 1rem;
  transition: 0.5s;
}
.top-btn img{
    border-radius: 50%;
    border: 4px solid var(--royalblue-color);
    overflow: hidden;
    mix-blend-mode: color-burn;
    object-fit: contain;

}