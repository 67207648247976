.slider-body {
  box-shadow: inset 7px -21px 38px -14px rgba(237, 237, 237, 1);
}
.client-main {
  width: 100%;
}
.slider-track {
  display: flex;
  width: calc(250px * 18);
  animation: scroll 60s linear infinite;
}
.slider-track:hover {
  animation-play-state: paused;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 9));
  }
}
.slider-div {
  height: 100px;
  width: 200px;
  display: flex;
  align-items: center;
  margin: 5px auto;
  perspective: 180px;
  outline: none;
}

.client-header {
  margin-top: 2rem;
  text-align: center;
  color: var(--royalblue-color);
}
/* .slider-img {
  width: 100%;
  height: 100%;
  aspect-ratio: 3/2;
  object-fit: contain;
  mix-blend-mode: color-burn;
  outline: none;
} */
@media screen and (max-width: 1100px) {
  .slider-div {
    width: 170px;
    height: 100px;
    margin: 15px;
  }
}
