.blog-section{
    width: 100%;
    margin: 20px 0;
}
.blog-section-box{
    width: 100%;
    display: flex;
}
.blog-section-part1{
    width: 70%;
}
.blog-section-part2{
    width: 30%;
    margin-left: 40px;
}
.blog-section-card{
    width: 100%;
    box-shadow: 0 0 15px 0 rgba(40, 61, 88, .07);
    margin-bottom: 2rem;
}
.blog-section-date{
    position: absolute;
    margin-top: -20px;
    margin-left: 20px;
    left: auto;
    padding: 10px;
    color: var(--white-color);
    background: var(--royalblue-color);
}
.blog-section-img{
    width: 100%;
    height: 15rem;
}
.blog-section-img img{
    object-fit: cover;
}
.blog-section-text{
    padding: 40px 20px;
    color: var(--primary-color);
}
.blog-section-line{
    height: 1px;
    border-top: 1px solid #ebebeb;
    display: block;
    position: relative;
    width: 100%;
    margin: 20px 0;
}
.blog-section-btn{
    color: var(--primary-color);
    font-size: 18px;
}
.blog-section-btn-icon{
  padding-left: 10px;
}
.news-title{
    color: var(--primary-color);
    cursor: pointer;
}
.blog-section-part2-box{
    color: var(--primary-color);
}
.blog-badge{
    cursor: pointer;
    border-bottom: 1px dashed var(--primary-color);
    font-size: 20px;
}
@media screen and (max-width: 630px){
   .blog-section-box{
    display: block;
   }
   .blog-section-part1, .blog-section-part2{
    width: 100%;
    margin-left: 0;
}
}