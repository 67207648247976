.logo{
    width: 12rem;
   }
   .menubar{
       margin: auto;
       color: var(--black-color);
       font-weight: 450;
       border: none;
       
   }
   .header{
       position: relative;
       width: 100%;
       height: 2rem;
       background-color: var(--royalblue-color);
   }
   .header-li a{
       color: var(--white-color);
       padding-right: 2rem;
   }
   .header-end{
      --bs-nav-link-padding-y: 0.2rem;
      display: flex;
      padding-top: 0.5rem;
      float: right;
   }
   
   
   .nav-main{
       position: fixed;
       background: var(--white-color);
       -webkit-box-shadow: 0px 1px 44px -10px rgba(0,0,0,0.75);
       -moz-box-shadow: 0px 1px 44px -10px rgba(0,0,0,0.75);
       box-shadow: 0px 1px 44px -10px rgba(0,0,0,0.75);
       width: 100%;
       z-index: 200;
       align-items: center;
       justify-content: space-between;
       border-bottom: 0.3rem solid var(--royalblue-color);
   }
   .main-nav{
       width: 100%;
       display: flex;
       align-items: center;
       justify-content: space-between;
       height: 75px;
       background: var(--white-color);
       color: var(--black-color);
       overflow: visible !important;
   }
   
   .nav-logo{
      width: 200PX;
   }
   
   .nav-links{
       display: flex !important;
       justify-content: flex-end !important;
       list-style: none;
       width: 75%;
       align-items: center;
   }
   
   .nav-home, .solution{
       text-decoration: none;
       color: var(--black-color) !important;
       font-size: 18px;
       font-weight: 500;
       padding: 10px 20px;
       margin: 0 10px;
       cursor: pointer;
   }
   .nav-home.active{
       border-radius: 20px;
       color: var(--royalblue-color) !important;
       text-decoration: underline !important;
       text-decoration-thickness: 4px !important;
       text-underline-offset: 5px;
   }
   
   .solution{
       border: 1px solid;
       border: none;
       border-radius: 0.5rem;
       color: var(--white-color) !important;
       background: var(--primary-color);
   }
    .solution:hover{
       color: var(--white-color) !important;
       background-color: var(--royalblue-color);
   }
   .nav-home:hover{
       color: var(--primary-color) !important;
   }
   .header-email{
       padding-right: 5px;
   }
   
   
   .whatsapp-chat{
       width: 3.5rem;
       position: fixed;
       bottom: 20px;
       left: 20px;
       border-radius: 550%;
       z-index: 99999;
   }
   .whatsapp-chat{
       animation: tilt-shaking 0.5s infinite;
   }
   @keyframes tilt-shaking {
       0% { transform: rotate(0deg); }
       25% { transform: rotate(10deg); }
       50% { transform: rotate(0eg); }
       75% { transform: rotate(-10deg); }
       100% { transform: rotate(0deg); }
     }
   .mobile-menu-icon{
       display: none;
   }
   
   .dropdowns{
       position: relative;
   }
   .dropdown-menus{
       background-color: rgb(255, 0, 0);
       color: black;
       height: 40rem;
       width: 20rem;
       position: fixed;
       display: none;
       z-index: 5000;
   }
   .dropdowns:hover .dropdown-menus{
       display: block;
       position: absolute;
   }
   .dropdown-menus a{
       display: block;
   }
   
   @media screen and (max-width: 1164px){
      .solution{
       padding: 10px 20px;
       font-size: 18px;
      }
   }
   @media screen and (max-width: 1130px){
      .solution{
       padding: 10px 20px;
       font-size: 16px;
      }
   }
   @media screen and (max-width: 1115px){
       .nav-home, .solution{
       padding: 10px 20px;
       font-size: 16px;
       margin: 0 5px;
      }
   }
   @media screen and (max-width: 980px){
       .nav-home, .solution{
       padding: 10px 20px !important;
       font-size: 14px !important;
       margin: 0 2px !important;
      }
   }
   @media screen and (max-width: 850px){
       .nav-home, .solution{
       padding: 10px 10px !important;
       font-size: 14px !important;
       margin: 0 !important;
      }
   }
   
   @media screen and (max-width: 780px) {
       .header{
           display: none !important;
       }
       .nav-logo{
           width: 150px;
           display: flex;
       }
       .nav-links{
           display: none !important;
       }
       .nav-links-mobile{
           position: absolute;
           display: block;
           list-style: none;
           background: var(--white-color);
           left: 0;
           top: 75px;
           transition: all 0.5s ease-out;
           width: 100%;
           height: 18rem;
           z-index: 100;
           align-items: center !important;
           justify-content: center !important;
           text-align: center;
       }
       .nav-home, .solution{
           color: var(--black-color);
           text-align: center;
           padding: 0;
           width: 100%;
           transition: all 0.5s ease;
           font-size: 20px;
       }
       .nav-home:hover{
           color: var(--primary-color);
       }
       .solution{
           border: 2px solid var(--primary-color);
           background: var(--primary-color);
           padding: 4px 10px;
           color: var(--white-color);
       }
       .mobile-menu-icon{
           display: block;
           position: absolute;
           font-size: 30px;
           color: var(--primary-color);
           background-color: var(--white-color);
           border: none;
           outline: none;
           right: 25px;
       }
       .nav-li{
           margin-bottom: 1rem;
       }
   }