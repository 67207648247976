.bulk-card-main {
  width: 100%;
}
.bulk-card-box {
  display: flex;
  width: 100%;
  margin-top: 4rem;
  flex-wrap: wrap;
}
.bulk-card {
  width: 50%;
  padding-left: 20px;
}
.bulksms-card1 {
  width: 50%;
  height: 25rem;
}

@media screen and (max-width: 991px) {
  .bulksms-card1 {
    width: 100%;
  }
  .bulk-card{
    padding-top: 2rem;
  }
}
@media screen and (max-width: 991px) {
  .bulk-card,
  .bulksms-card1 {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .bulk-card-box {
    margin-top: 1rem;
  }
  .bulksms-card1 {
    height: 20rem;
  }
}
